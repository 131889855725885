@mixin h1 {
  font-family: TextBook, sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@mixin h1Mobile {
  font-family: TextBook, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@mixin h15 {
  font-family: TextBook, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.03em;
}

@mixin h15Mobile {
  font-family: TextBook, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.03em;
}

@mixin h2 {
  font-family: TextBook, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@mixin h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0.03em;
}

@mixin h4 {
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.03em;
}

@mixin h5 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.03em;
}

@mixin h6 {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.03em;
}

@mixin h7 {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
}

@mixin pCitation {
  font-size: 22px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0;
}

@mixin p2Citation {
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0;
}

@mixin p1 {
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0;
}

@mixin p2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0;
}

@mixin p3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
}

@mixin t1 {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
}

@mixin t2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
}

@mixin t3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.03em;
}

@mixin t4 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
}

@mixin t5 {
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: uppercase;
}

@mixin t6 {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.03em;
}

@mixin t7 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
}

@mixin number {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0;
}

@mixin b1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: uppercase;
}

@mixin b2 {
  font-size: 12px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@mixin b3 {
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@mixin b4 {
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0;
}

@mixin message {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0;
}

@mixin messageMob {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
}

@mixin cardSMTextDesktop {
  font-family: TextBook, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@mixin cardSMTextMobile {
  font-family: TextBook, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: uppercase;
}

@mixin italic {
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
}

@mixin menuHeader {
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
}

@mixin pinData {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
}

@mixin contentH {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0;
}

@mixin contentH2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0;
}

@mixin cardMain {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
}

@mixin headerBlockTitle {
  font-family: TextBook, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0;
  text-transform: uppercase;
}

@mixin recBlockTitle {
  font-family: TextBook, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@mixin recBlockTitleMobile {
  font-family: TextBook, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@mixin districtHeroTitle {
  font-size: 68px;
  font-weight: 500;
  line-height: 70px;
  letter-spacing: 0;
}

@mixin districtHeroSubTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0;
}

@mixin districtHeroTitleTablet {
  font-size: 48px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
}

@mixin districtHeroSubTitleTablet {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
}

@mixin districtHeroTitleMobile {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
}

@mixin districtHeroSubTitleMobile {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
}

@mixin districtCardTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
}

// index page video banner and widgets typography

@mixin bannerWidgetPrimaryDesktop {
  font-size: 32px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0;
  text-transform: uppercase;
}

@mixin bannerWidgetSecondaryDesktop {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
}

@mixin bannerWidgetPrimaryMobile {
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0;
  text-transform: uppercase;
}

@mixin bannerWidgetSecondaryMobile {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
}

@mixin bannerTitleDesktop {
  font-family: TextBook, sans-serif;
  font-size: 96px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0;
  text-transform: uppercase;
}

@mixin bannerTitleTablet {
  font-family: TextBook, sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0;
  text-transform: uppercase;
}

@mixin bannerTitleMobile {
  font-family: TextBook, sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0;
  text-transform: uppercase;
}
